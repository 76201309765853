@font-face {
  font-family: "Poppins";
  src: url("assets/fonts/Poppins-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("assets/fonts/Poppins-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("assets/fonts/Poppins-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("assets/fonts/Poppins-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

* {
  font-family: "Poppins";
  letter-spacing: 0.02em;
  line-height: 110%;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "Poppins";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Poppins";
}

html,
body,
#root {
  min-height: 100%;
}

.router {
  width: 100%;
  height: 100%;
}

html::-webkit-scrollbar {
  width: 10px;
  height: 0px;
}
html::-webkit-scrollbar-track {
  box-shadow: inset 0 0 25px #878ca180;
  border-radius: 10px;
}

html::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px #878ca1;
}

#nprogress {
  pointer-events: none;
}

#nprogress .bar {
  background: #fe4d57;
  position: fixed;
  z-index: 50;
  top: 0;
  left: 0;
  width: 100%;
  height: 6px;
}

#nprogress .peg {
  display: block;
  position: absolute;
  right: 0px;
  width: 100px;
  height: 100%;
  box-shadow: 0 0 10px #fe4d57, 0 0 5px #fe4d57;
  opacity: 1;

  -webkit-transform: rotate(3deg) translate(0px, -4px);
  -ms-transform: rotate(3deg) translate(0px, -4px);
  transform: rotate(3deg) translate(0px, -4px);
}

/* Remove these to get rid of the spinner */
#nprogress .spinner {
  display: block;
  position: fixed;
  z-index: 51;
  top: 15px;
  right: 15px;
}

#nprogress .spinner-icon {
  width: 20px;
  height: 20px;
  box-sizing: border-box;

  border: solid 2px transparent;
  border-top-color: #fe4d57;
  border-left-color: #fe4d57;
  border-radius: 50%;

  -webkit-animation: nprogress-spinner 400ms linear infinite;
  animation: nprogress-spinner 400ms linear infinite;
}

.nprogress-custom-parent {
  overflow: hidden;
  position: relative;
}

.nprogress-custom-parent #nprogress .spinner,
.nprogress-custom-parent #nprogress .bar {
  position: absolute;
}

@-webkit-keyframes nprogress-spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes nprogress-spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

div:focus,
div:active {
  outline: none;
}
